import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Add, Delete } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize, Slider } from '@mui/material';

import {
  TaskBarComponent,
  TaskBarGroup,
  TaskBarHeader,
} from 'features/task_bar/TaskBar';
import {
  OverflowLabel,
  SplitOverflowLabel,
} from 'features/task_bar/OverflowLabel';
import { useGetSegmentInfoQuery } from 'state/apiSlice';
import { LayerContext } from 'state/LayerContext';
import { PlanningMonthPicker } from '../task_bar/PlanningMonthPicker';
import styles from './WorkflowPredict.module.css';
import { TaskBarSelect } from '../task_bar/TaskBarSelect';
import {
  selectProject,
  selectSegmentId,
  selectTypicalMonth,
  selectCurrentScenarioId,
  setCurrentScenarioId,
  setTypicalMonth,
  setSelectedSegmentId,
} from '../../state/workflowSlice';
import {
  SCENARIO_DONE,
  SCENARIO_ERROR,
  SCENARIO_PENDING,
  selectScenarioCapacity,
  selectScenarioDayType,
  selectScenarioEnd,
  selectScenarioMaxDetours,
  selectScenarioSegments,
  selectScenarioStart,
  selectScenarioStatus,
  setScenarioCapacity,
  setScenarioDayType,
  setScenarioEnd,
  setScenarioMaxDetours,
  setScenarioSegments,
  setScenarioStart,
  useCreateScenarioMutation,
  useGetScenarioStatusQuery,
} from '../../state/predictSlices';
import {
  stl_background_color,
  stl_light_background_color,
  stl_light_grey,
  taskbar_color,
  workspace_color,
} from '../../theme/cemTheme';

function dropFromArray(array_in, item) {
  const array = [...array_in];
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function PredictSettingsData() {
  const project_slug = useSelector(selectProject);
  const dispatch = useDispatch();
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const { currentData: scenarioData } = useGetScenarioStatusQuery(
    { project_slug, uuid: currentScenarioId },
    {
      skip: !currentScenarioId,
    },
  );

  useEffect(() => {
    if (scenarioData) {
      dispatch(setScenarioDayType(scenarioData.scenario.day_type));
      dispatch(
        setScenarioStart(scenarioData.scenario.closure_start.slice(0, -3)),
      );
      dispatch(setScenarioEnd(scenarioData.scenario.closure_end.slice(0, -3)));
      dispatch(setTypicalMonth(scenarioData.scenario.year_month));
      dispatch(setSelectedSegmentId(scenarioData.scenario.segments[0]));
      dispatch(setScenarioSegments(scenarioData.scenario.segments));
      dispatch(
        setScenarioCapacity(scenarioData.scenario.new_capacities[0] || 0.0),
      );
      dispatch(
        setScenarioMaxDetours(scenarioData.scenario.max_detours_per_hour),
      );
    } else if (currentScenarioId === undefined) {
      dispatch(setSelectedSegmentId(undefined));
      dispatch(setScenarioSegments([]));
    }
  }, [scenarioData]);

  return null;
}

export function PredictSettings() {
  const dayType = useSelector(selectScenarioDayType);
  const closureStart = useSelector(selectScenarioStart);
  const closureEnd = useSelector(selectScenarioEnd);
  const scenarioSegments = useSelector(selectScenarioSegments);
  const selectedSegmentId = useSelector(selectSegmentId);
  const scenarioStatus = useSelector(selectScenarioStatus);
  const project_slug = useSelector(selectProject);
  const typicalMonth = useSelector(selectTypicalMonth);
  const capacity = useSelector(selectScenarioCapacity);
  const [createScenario, scenarioResult] = useCreateScenarioMutation();
  const { status, data: mutateScenarioData } = scenarioResult;
  const dispatch = useDispatch();
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const { layer } = useContext(LayerContext);

  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [selectedSegmentId] },
    { skip: !layer || !selectedSegmentId },
  );

  const calculateClick = async (event) => {
    console.log(dayType, closureStart, closureEnd, selectedSegmentId);
    await createScenario({
      scenario_settings: {
        day_type: dayType,
        closure_start: closureStart,
        closure_end: closureEnd,
        segments: [selectedSegmentId],
        new_capacities: [capacity],
        project_slug,
        year_month: typicalMonth,
      },
      project_slug,
    })
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setCurrentScenarioId(res.scenario.uuid));
      });
  };

  let is_loading = false;
  let button_copy = 'Calculate scenario';
  if (status === 'pending' || (!scenarioStatus && currentScenarioId)) {
    button_copy = 'Loading...';
    is_loading = true;
  } else if (currentScenarioId && scenarioStatus !== SCENARIO_DONE) {
    if (scenarioStatus === SCENARIO_PENDING) {
      button_copy = 'Processing scenario';
    } else {
      button_copy = `Calculating scenario (${capitalize(scenarioStatus)})`;
    }
    is_loading = true;
  }

  const times = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const t = `${i.toFixed(0).padStart(2, '0')}:${(j * 15)
        .toFixed(0)
        .padStart(2, '0')}`;
      times.push({ value: t, label: t });
    }
  }

  const formatCapacityLabel = (v) => {
    let ret = `${v}%`;
    if (v === 0) {
      ret += ' - Closed';
    } else if (v === 100) {
      ret += ' - Open';
    }
    return ret;
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <div className={styles.task_bar_group_settings}>
      <TaskBarComponent>
        <Button
          onClick={() => {
            dispatch(setCurrentScenarioId(undefined));
          }}
        >
          Clear scenario from map
        </Button>
      </TaskBarComponent>
      <PlanningMonthPicker />
      <TaskBarSelect
        label="Day Type:"
        value={dayType}
        onChange={(event) => {
          dispatch(setScenarioDayType(event.target.value));
        }}
        narrow
        values={[
          { value: 'Weekday', label: 'Weekday' },
          { value: 'Saturday', label: 'Saturday' },
          { value: 'Sunday', label: 'Sunday' },
        ]}
      />
      <TaskBarSelect
        label="Closure start:"
        value={closureStart || ''}
        onChange={(event) => {
          dispatch(setScenarioStart(event.target.value));
        }}
        values={times}
        narrow
      />
      <TaskBarSelect
        label="Closure end:"
        value={closureEnd || ''}
        onChange={(event) => {
          dispatch(setScenarioEnd(event.target.value));
        }}
        values={times}
        narrow
      />
      <TaskBarComponent left>
        Segment:
        {segmentData?.segments[selectedSegmentId]?.properties?.name ||
          selectedSegmentId}
      </TaskBarComponent>
      <TaskBarComponent left>
        Remaining Segment Capacity:
        <Slider
          value={capacity}
          onChange={(ev, value) => {
            return dispatch(setScenarioCapacity(value));
          }}
          max={100}
          min={0}
          valueLabelDisplay="on"
          valueLabelFormat={formatCapacityLabel}
          sx={{
            marginBottom: '2em !important',
            marginLeft: '1em',
            marginRight: '1em',
            width: 'auto',
            '.MuiSlider-valueLabel': {
              marginTop: '1px',
              width: '20em',
              color: 'var(--primary-text)',
              background: 'none',
              transform: 'translateY(33px) !important',
            },
          }}
        />
      </TaskBarComponent>
      <Button
        disabled={!(dayType && closureStart && closureEnd && selectedSegmentId)}
        onClick={calculateClick}
        variant="contained"
        startIcon={
          is_loading && (
            <CircularProgress
              style={{ width: '1em', height: '1em', color: taskbar_color }}
            />
          )
        }
      >
        {button_copy}
      </Button>
      {is_loading && <i>ETA ~60 seconds</i>}
    </div>
  );
}
