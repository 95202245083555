import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import mapboxgl from 'mapbox-gl';
import styles from 'features/map/Map.module.css';

import {
  selectSegmentId,
  setSelectedSegmentId,
  setMapCenter,
  setMapZoom,
  selectProject,
  setRouteSelection,
  selectRouteSelection,
  selectCurrentProjectInfo,
  selectMapCenter,
  selectTargetDate,
  selectTimeOfDay,
  selectTimelineMetric,
  selectTypicalMonth,
  selectMapZoom,
  setSlowdownThreshold,
  selectCurrentScenarioId,
  selectScenarioSetupUIMode,
} from 'state/workflowSlice';
import { useGetLayerQuery } from 'state/apiSlice';

import { LayerContext } from 'state/LayerContext';

import { optionallyCall } from 'appUtils';
import {
  ToggleLayerControl,
  toggleLayerVisibility,
  enableChartButton,
  ensureRtlLoaded,
  findNearestSegmentLatLng,
  getLayerToggleVisibility,
  launderLatLng,
  latLngToJsonPoint,
} from 'features/map/mapUtils';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'features/map/Map.css';
import {
  kHalfDirectionArrowNameLHD,
  kHalfDirectionArrowNameRHD,
} from 'features/map/MapIconProvider';
import {
  kSegmentLayerId,
  initMap,
  mapShowingSelectedSegment,
  mapShowingSegments,
  mapPopSelectedSegment,
  getInitialCenter,
  thick_line_width_expression,
  kSegmentSymbolsLayerId,
  kSegmentLayerArrowDefinition,
  icon_size_expression,
  redrawBaseSegments,
  kMainVisLineOpacityExpression,
  kSegmentHoverId,
  kSegmentLayerSourceLayerId,
  showBookmarks,
  safeRemoveBookmarks,
  updateMapLocation,
  kSegmentSourceId,
  redrawSelectedSegment,
  getSelectionFromClickEvent,
  kSegmentLayerDefinition,
  getDrawableMapSegments,
  kSelectedSegmentLayerId,
  mapPushSelectedSegment,
  mapHasLayer,
  mapLayerSafeRemove,
  mapSourceSafeRemove,
  kScenarioSegmentLayerId,
  kSelectedSegmentLayerDefinition,
  kScenarioSegmentsLayerDefinition,
} from 'features/map/mapCommon';
import { transparent_null_grey } from 'theme/cemTheme';

import { formatSpeed, KM_TO_MILES } from '../common/utils';
import { mapboxApiKey, ScenarioSetupUIMode } from '../../appConstants';
import {
  selectDisplayedBookmark,
  selectInLocationEditMode,
  setDisplayedBookmark,
} from '../../state/bookmarkSlices';
import { FloatingDiv } from '../spinner/Spinner';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import {
  minutes_to_slots,
  formatTimeOfDay,
} from '../workflow_timeline/slotsUtils';
import {
  TL_METRIC_OPTIONS,
  counts_absolute_size_expr,
  metricExprDeepSub,
} from './metricsOptions';
import {
  SCENARIO_DONE,
  selectScenarioMaxDetours,
  selectScenarioSegments,
  selectScenarioStatus,
} from '../../state/predictSlices';
import { TimelineLegend } from '../workflow_timeline/TimelineLegend';

mapboxgl.accessToken = mapboxApiKey;

const kColorUpdateMillis = 200;
const kMouseHoverTimeoutMillis = 300;
const kTicksShowHideId = 'ticks-showhide';

let lastColorUpdate;
let lastColorUpdateRef;

const timelineMinZoom = 12;

export function PredictMap() {
  // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

  const dispatch = useDispatch();

  // useRef DOM refs and for variables accessed from map callbacks
  const refMap: React.RefObject<mapboxgl.Map | null> = useRef(null); // mapboxgl.Map object
  const refPopup = useRef<mapboxgl.Popup>(null); // mapboxgl.Map object
  const refMapContainer = useRef(null); // DOM node reference
  const refRouting = useRef({});
  const refMouseoverTimeout = useRef(null);

  const [mapReady, setMapReady] = useState(false);
  const [zoomPrompt, setZoomPrompt] = useState(true);
  const { layer } = useContext(LayerContext);
  const selectedSegmentId = useSelector(selectSegmentId);
  const selectedRoute = useSelector(selectRouteSelection);
  const project = useSelector(selectProject);
  const currentMapCenter = useSelector(selectMapCenter);
  const mapZoom = useSelector(selectMapZoom);
  const targetDate = useSelector(selectTargetDate);
  const timeOfDay = useSelector(selectTimeOfDay);
  const userProject = useSelector(selectCurrentProjectInfo);
  const binSize = Math.min(...userProject.available_bins);
  const timelineMetric = useSelector(selectTimelineMetric);
  const typicalMonth = useSelector(selectTypicalMonth);
  const inLocationEditMode = useSelector(selectInLocationEditMode);
  const currentScenarioId = useSelector(selectCurrentScenarioId);
  const scenarioStatus = useSelector(selectScenarioStatus);
  const displayedBookmark = useSelector(selectDisplayedBookmark);
  const scenarioSegments = useSelector(selectScenarioSegments);
  const uiMode = useSelector(selectScenarioSetupUIMode);
  const max_detours = useSelector(selectScenarioMaxDetours);
  const hourlyMaxCount = useRef(max_detours);

  const refSelection = useRef({
    segment: selectedSegmentId,
    route: selectedRoute,
    segments: scenarioSegments,
  });

  const timeOfDayOffsetPre = minutes_to_slots(timeOfDay, binSize);
  const timeOfDayOffset =
    timeOfDay === 60 * 24 ? minutes_to_slots(0, binSize) : timeOfDayOffsetPre;
  const timeOfDayOffsetRef = useRef({
    offset: timeOfDayOffset,
    time: timeOfDay,
  });
  timeOfDayOffsetRef.current = { offset: timeOfDayOffset, time: timeOfDay };
  const refInLocationEditMode = useRef(inLocationEditMode);
  const refEnableRouteSelection = useRef(
    uiMode === ScenarioSetupUIMode.TravelTimes,
  );
  // load segmentData for layer from REST api (or cache)
  const { currentData: layerData } = useGetLayerQuery(layer, { skip: !layer });
  // useSegmentSelectionCrosswalk();

  refRouting.current = useMemo(() => layerData?.routing, [layerData]);

  ensureRtlLoaded();

  function getColorExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    const NULL_PLACEHOLDER = 1000000;
    if (!setting) {
      console.error(`Setting not found for metric ${metric}`);
      return undefined;
    }
    const settingsArgs = {
      timeOfDayOffset,
      hourlyMaxCount: Number(hourlyMaxCount.current),
      uses_metric: userProject.uses_metric,
    };
    const metric_expr = setting.metric_expr(settingsArgs);
    console.log(metric_expr);
    return [
      'interpolate',
      ['linear'],
      ['coalesce', metric_expr, NULL_PLACEHOLDER],
      ...optionallyCall(setting.colors, settingsArgs),
      NULL_PLACEHOLDER,
      transparent_null_grey,
    ];
  }

  function getShowExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (!setting) {
      console.error(`Setting not found for metric ${metric}`);
      return undefined;
    }
    const settingsArgs = {
      timeOfDayOffset,
      hourlyMaxCount: Number(hourlyMaxCount.current),
      uses_metric: userProject.uses_metric,
    };
    if (!setting.shown) {
      return true;
    } else {
      const shown = metricExprDeepSub(
        [...setting.shown],
        setting.metric_expr(settingsArgs),
      );
      return shown;
    }
  }

  function getLineWidthExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (setting?.width === 'count_absolute') {
      const counts_expr = (base) =>
        counts_absolute_size_expr(
          timeOfDayOffset,
          Number(hourlyMaxCount.current),
          base,
        );
      return [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        counts_expr(1),
        14,
        counts_expr(3),
        16,
        counts_expr(8),
        19,
        counts_expr(20),
      ];
    } else {
      return thick_line_width_expression;
    }
  }

  function getLineOffsetExpression() {
    let sign = -1;
    if (userProject.right_hand_drive) {
      sign = 1;
    }
    return [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      sign * 1,
      14,
      sign * 2,
      16,
      sign * 3,
      19,
      sign * 5,
    ];
  }

  function getIconSizeExpression(metric) {
    const setting = TL_METRIC_OPTIONS[metric];
    if (setting?.width === 'count_absolute') {
      const counts_expr = (base) =>
        counts_absolute_size_expr(
          timeOfDayOffset,
          Number(hourlyMaxCount.current),
          base,
        );
      return [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        counts_expr(0.4),
        14,
        counts_expr(0.4),
        16,
        counts_expr(0.8),
        19,
        counts_expr(1),
      ];
    } else {
      return icon_size_expression;
    }
  }

  const segmentLayerDefinition = {
    id: kSegmentLayerId,
    type: 'line',
    source: kSegmentSourceId,
    'source-layer': kSegmentLayerSourceLayerId,
    layout: {},
    paint: {
      'line-color': getColorExpression(timelineMetric),
      'line-width': getLineWidthExpression(timelineMetric),
      'line-opacity': 0.7,
      'line-offset': getLineOffsetExpression(),
    },
  };

  const segmentArrowLayerDefinition = {
    ...kSegmentLayerArrowDefinition,
    paint: {
      'icon-color': getColorExpression(timelineMetric),
      'icon-opacity': kMainVisLineOpacityExpression(0.8),
    },
  };

  segmentArrowLayerDefinition.layout['icon-image'] =
    userProject.right_hand_drive
      ? kHalfDirectionArrowNameRHD
      : kHalfDirectionArrowNameLHD;
  segmentArrowLayerDefinition.layout['icon-size'] = getIconSizeExpression(
    timelineMetric,
  ) as any;
  (segmentArrowLayerDefinition.layout as any).visibility =
    getLayerToggleVisibility(kTicksShowHideId) ? 'visible' : 'none';

  const hoverLayerDefinition = {
    ...segmentLayerDefinition,
    id: kSegmentHoverId,
    paint: {
      ...segmentLayerDefinition.paint,
      'line-color': '#000000',
      'line-opacity': 0,
    },
  };

  function updateColorExpression() {
    lastColorUpdate = Date.now();
    refMap.current.setFilter(
      kSegmentLayerId,
      getShowExpression(timelineMetric),
    );
    refMap.current.setPaintProperty(
      kSegmentLayerId,
      'line-width',
      getLineWidthExpression(timelineMetric),
    );
    refMap.current.setPaintProperty(
      kSegmentLayerId,
      'line-color',
      getColorExpression(timelineMetric),
    );
    if (getLayerToggleVisibility(kTicksShowHideId)) {
      refMap.current.setPaintProperty(
        kSegmentSymbolsLayerId,
        'icon-color',
        getColorExpression(timelineMetric),
      );
      const ice = getIconSizeExpression(timelineMetric);
      refMap.current.setLayoutProperty(
        kSegmentSymbolsLayerId,
        'icon-size',
        getIconSizeExpression(timelineMetric),
      );
    }
  }

  function dispatchSelectionRedraw(force = false) {
    if (refMap.current) {
      redrawSelectedSegment(
        mapReady,
        refMap.current,
        refSelection.current.segment,
        refRouting.current,
        refSelection.current.route,
        force,
      );
    }
  }

  function dispatchScenarioSegmentsRedraw(force = false) {
    if (refSelection.current.segments && refMap.current) {
      const { needsRedraw, drawable } = getDrawableMapSegments(
        refMap.current,
        refSelection.current.segments,
        kScenarioSegmentLayerId,
        force,
      );
      if (needsRedraw) {
        if (mapHasLayer(refMap.current, kScenarioSegmentLayerId)) {
          mapLayerSafeRemove(refMap.current, kScenarioSegmentLayerId);
          mapSourceSafeRemove(refMap.current, kScenarioSegmentLayerId);
        }
        refMap.current.addSource(kScenarioSegmentLayerId, {
          type: 'geojson',
          data: drawable as any,
        });
        let under;
        if (mapHasLayer(refMap.current, kSelectedSegmentLayerId)) {
          under = kSelectedSegmentLayerId;
        }
        refMap.current.addLayer(kScenarioSegmentsLayerDefinition as any, under);
      }
    }
  }

  const handleSegmentClickCallback = useCallbackWithErrorHandling((event) => {
    // console.log(`handleSegmentClickCallback ${JSON.stringify(event.lngLat)} refSegments.current ${JSON.stringify(refSegments.current.length)}`);
    if (refInLocationEditMode.current) {
      dispatch(setDisplayedBookmark(launderLatLng(event.lngLat)));
    } else {
      const {
        segmentId: newSelectedSegmentId,
        segment: selectedSegment,
        route,
      } = getSelectionFromClickEvent(
        event,
        refMap.current,
        refRouting,
        refEnableRouteSelection.current,
      );
      if (route) {
        dispatch(setRouteSelection(route));
      } else if (route === null) {
        dispatch(setRouteSelection(undefined));
      }
      dispatch(setSelectedSegmentId(newSelectedSegmentId));
      dispatch(setSlowdownThreshold(undefined));
    }
  });

  function showMouseTooltipForFeature(feature, lngLat) {
    refMap.current.getCanvas().style.cursor = 'pointer';
    const NA = 'No sample';
    if (refMouseoverTimeout.current) {
      clearTimeout(refMouseoverTimeout.current);
    }
    const { nearestSegmentPointLatLng } = findNearestSegmentLatLng(
      [feature],
      lngLat,
    );
    let description;

    function formatSpeeds(value) {
      if (!value || value.toString() === '-1') {
        return NA;
      }
      return formatSpeed(value * KM_TO_MILES, userProject.uses_metric);
    }

    function formatCounts(value) {
      if (!value) {
        return NA;
      }
      return value.toString().split('.')[0];
    }

    function indexIntoProperty(featurex, prefix) {
      const value =
        featurex.properties[`${prefix}${timeOfDayOffsetRef.current.offset}`];
      if (!value || value === '-1') {
        return undefined;
      } // else {
      //   // @ts-ignore: Use array destructuring
      //   // eslint-disable-next-line
      //   value = value.split('.')[0];
      // }
      return value.toString();
    }

    const time = formatTimeOfDay(timeOfDayOffsetRef.current.time);

    const counts = indexIntoProperty(feature, 'c');
    const speeds = indexIntoProperty(feature, 's');
    const freeflowSpeed = formatSpeeds(
      feature.properties.freeflow_speed_kph ||
        feature.properties.vnrt_freeflow_kph,
    );

    if (feature.properties.tc1) {
      const typicalCounts = indexIntoProperty(feature, 'tc');
      const typicalSpeeds = indexIntoProperty(feature, 'ts');
      description = `
          <h1>${feature.properties.name}</h1>
          <table>
            <tr><td><b>Speed at ${time}</b></td><td><b>${formatSpeeds(
        speeds,
      )}</b></td></tr>
            <tr><td>Typical speed</td><td>${formatSpeeds(
              typicalSpeeds,
            )}</td></tr>
            <tr class="space-under"><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>
            <tr><td><b>Scenario volume</b></td><td><b>${formatCounts(
              counts,
            )}</b></td></tr>
            <tr><td>Typical count</td><td>${formatCounts(
              typicalCounts,
            )}</td></tr>
          </table>`;
    } else {
      // Copy coordinates array.
      description = `
          <h1>${feature.properties.name}</h1>
          <table>
            <tr><td>Speed</td><td>${formatSpeeds(speeds)}</td></tr>
            <tr><td>Trip count</td><td>${formatCounts(counts)}</td></tr>
            <tr><td>Freeflow speed</td><td>${freeflowSpeed}</td></tr>
          </table>`;
    }

    if (description && Number.isFinite(nearestSegmentPointLatLng?.lat)) {
      // Populate the popup and set its coordinates
      // based on the feature found.
      refPopup.current
        .setLngLat(nearestSegmentPointLatLng)
        .setHTML(description)
        .addTo(refMap.current);
    }
  }

  function hideMouseover() {
    refPopup.current.remove();
    refMouseoverTimeout.current = undefined;
  }

  const handleMouseLeaveEvent = useCallbackWithErrorHandling((event) => {
    if (!refMouseoverTimeout.current) {
      if (refPopup.current.isOpen()) {
        refMouseoverTimeout.current = {
          handle: setTimeout(hideMouseover, kMouseHoverTimeoutMillis),
          set: Date.now(),
        };
      }
    } else if (
      Date.now() - refMouseoverTimeout.current.set >
      kMouseHoverTimeoutMillis * 2
    ) {
      hideMouseover();
    }
  });

  function getNearestSegmentFromMouseEvent(event: mapboxgl.MapMouseEvent) {
    const { point } = event;
    const width = 8;
    const height = 8;
    if (!mapShowingSegments(refMap.current)) return undefined;
    const features = refMap.current.queryRenderedFeatures(
      [
        [point.x - width / 2, point.y - height / 2],
        [point.x + width / 2, point.y + height / 2],
      ],
      { layers: [kSegmentHoverId] },
    );
    if (features.length > 0) {
      const { nearestSegment } = findNearestSegmentLatLng(
        features as any,
        event.lngLat,
      );
      return nearestSegment;
    }

    return undefined;
  }

  const handleMouseMoveEvent = useCallbackWithErrorHandling(
    (event: mapboxgl.MapMouseEvent) => {
      const nearestSegment = getNearestSegmentFromMouseEvent(event);
      if (nearestSegment) {
        showMouseTooltipForFeature(nearestSegment, event.lngLat);
      } else {
        handleMouseLeaveEvent(event);
      }
    },
  );

  const handleMapMoveCallback = useCallbackWithErrorHandling(() => {
    const center = {
      lng: refMap.current.getCenter().lng.toFixed(4),
      lat: refMap.current.getCenter().lat.toFixed(4),
    };
    const newZoom = refMap.current.getZoom().toFixed(2);
    dispatch(setMapZoom(newZoom));
    dispatch(setMapCenter(center));
  });

  const handleSourceDataEvent = useCallbackWithErrorHandling(
    (e: mapboxgl.MapSourceDataEvent) => {
      // Given we now rely on the segments being loaded via tiles to render
      // Selected and routes, we have to listen to new tiles being rendered
      // In case the selected segments or any of the route segments are suddenly in view
      if (e.sourceId === kSegmentSourceId) {
        dispatchSelectionRedraw();
        dispatchScenarioSegmentsRedraw();
      }
    },
  );

  function handleMapLoadCallback() {
    setMapReady(true);
  }

  function handleTicksShowHideClickCallback() {
    toggleLayerVisibility(
      kTicksShowHideId,
      kSegmentSymbolsLayerId,
      refMap.current,
    );
  }

  function handleZoomPromptUpdate() {
    if (refMap.current) {
      if (refMap.current.getZoom() < timelineMinZoom) {
        setZoomPrompt(true);
      } else {
        setZoomPrompt(false);
      }
    }
  }

  function handleKeyStroke(event) {
    const code = event.which || event.keyCode;
    const charCode = String.fromCharCode(code).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 'z') {
      if ((refSelection.current.route || []).length > 0) {
        dispatch(setRouteSelection(refSelection.current.route.slice(0, -1)));
        dispatch(setSelectedSegmentId(refSelection.current.route.slice(-2)[0]));
      }
    }
  }

  useEffect(() => {
    if (!refMap.current) {
      const { center, zoom } = getInitialCenter(userProject);
      if (center) {
        initMap(
          refMap,
          refMapContainer,
          center,
          zoom,
          undefined,
          false,
          handleMapLoadCallback,
          handleMapMoveCallback,
          handleSegmentClickCallback,
          () => {},
          dispatch,
          userProject,
        );
        lastColorUpdate = Date.now();
        refPopup.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });
        refMap.current.on('mousemove', handleMouseMoveEvent);
        refMap.current.addControl(
          new ToggleLayerControl(kTicksShowHideId, 'Show/hide ticks'),
        );
        refMap.current.on('sourcedata', handleSourceDataEvent);
        refMap.current.on('zoomend', handleZoomPromptUpdate);
        handleZoomPromptUpdate();
      } else {
        console.log(
          'Project defaults not yet loaded, skipping initialisation of map',
        );
      }
    }
  }, [userProject]); // eslint-disable-line react-hooks/exhaustive-deps

  // redrawSegments
  useEffect(
    () => {
      // console.log(`============= useeffect redrawSegments (mapReady: ${mapReady}`);
      if (mapReady && refMap.current) {
        const scenarioReady =
          currentScenarioId && scenarioStatus === SCENARIO_DONE;
        redrawBaseSegments(
          mapReady,
          refMap.current,
          scenarioReady ? segmentLayerDefinition : kSegmentLayerDefinition,
          scenarioReady
            ? `server/predict/tiles/scenario/${currentScenarioId}`
            : `server/tiles/${layer}`,
          segmentArrowLayerDefinition,
          hoverLayerDefinition,
          timelineMinZoom,
          16,
        );
      }
    },
    // we don't need dependency on selectedSegmentId even though we draw the selected statement
    // because (besides initial render) it only erasing/drawing it is handled by handleSegmentClickCallback
    [mapReady, currentScenarioId, scenarioStatus], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    refSelection.current.segment = selectedSegmentId;
    refSelection.current.route = selectedRoute;
    refSelection.current.segments = scenarioSegments;
    dispatchSelectionRedraw();
    dispatchScenarioSegmentsRedraw();
  }, [selectedRoute, selectedSegmentId, scenarioSegments]);

  useEffect(() => {
    if (refMap.current) {
      enableChartButton(`#${kTicksShowHideId}`, true);
      document.querySelector<HTMLElement>(`#${kTicksShowHideId}`).onclick =
        handleTicksShowHideClickCallback;
    }
  }, [mapReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refMap.current) {
      const { center } = getInitialCenter(userProject);
      refMap.current.jumpTo({ center });
    }
  }, [project]);

  useEffect(() => {
    if (mapShowingSegments(refMap.current)) {
      if (
        !lastColorUpdate ||
        Date.now() - lastColorUpdate > kColorUpdateMillis
      ) {
        clearTimeout(lastColorUpdateRef);
        updateColorExpression();
      } else {
        clearTimeout(lastColorUpdateRef);
        lastColorUpdateRef = setTimeout(
          updateColorExpression,
          kColorUpdateMillis,
        );
      }
    }
  }, [timeOfDayOffset, timelineMetric, targetDate]);

  useEffect(() => {
    updateMapLocation(refMap.current, mapZoom, currentMapCenter);
  }, [currentMapCenter, mapZoom]);

  useEffect(() => {
    refEnableRouteSelection.current =
      uiMode === ScenarioSetupUIMode.TravelTimes;
  }, [uiMode]);

  useEffect(() => {
    refInLocationEditMode.current = inLocationEditMode;
    if (mapReady) {
      if (inLocationEditMode) {
        if (!displayedBookmark) {
          dispatch(setDisplayedBookmark({ ...refMap.current.getCenter() }));
        } else {
          const bookmarks = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: latLngToJsonPoint(displayedBookmark),
              },
            ],
          };
          showBookmarks(refMap.current, bookmarks);
        }
      } else {
        safeRemoveBookmarks(refMap.current);
      }
    }
  }, [mapReady, dispatch, inLocationEditMode, displayedBookmark]);

  useEffect(() => {
    if (refMap.current) {
      refMap.current.resize();
    }
  }, [uiMode]);

  useEffect(() => {
    if (max_detours) {
      hourlyMaxCount.current = max_detours;
    } else {
      hourlyMaxCount.current = 1000;
    }
  }, [max_detours]);

  return (
    // eslint-disable-next-line  jsx-a11y/no-static-element-interactions
    <div ref={refMapContainer} className={styles.map} onKeyUp={handleKeyStroke}>
      <TimelineLegend
        metric={timelineMetric}
        hourlyMaxCount={hourlyMaxCount.current}
        metricsOptions={TL_METRIC_OPTIONS}
      />
      {zoomPrompt && <FloatingDiv>Zoom in to view timeline</FloatingDiv>}
    </div>
  );
}
