import {
  COLORMAP_MINUS_1_GREY,
  COLORMAP_TRAFFICLIGHT_0_1,
  COLORMAP_TRAFFICLIGHT_100_0,
  COLORMAP_VOLUME_M1_1,
  viridis,
} from '../chart/colorMaps';

export const TL_METRIC_ABS_SPEED = 'speed_abs';
export const TL_METRIC_FF_REL_SPEED = 'speed_ff';
export const TL_METRIC_ABS_VOL = 'count_abs';
export const TL_METRIC_TYP_REL_SPEED = 'speed_typ';
export const TL_METRIC_TYP_REL_VOL = 'count_typ';
export const TL_METRIC_TYP_REL_SPEED_VOL = 'count_speed_typ';
export const TL_METRIC_FF_REL_SPEED_VOL = 'count_speed_ff';
export const TL_METRIC_FF_VHD = 'vhd';
export const TL_METRIC_TYP_VHD = 'typ_vhd';

export const METRIC_PLACEHOLDER = 'metric';

export const TL_METRIC_OPTIONS_WITH_TYPICAL = [
  TL_METRIC_TYP_REL_SPEED,
  TL_METRIC_TYP_REL_VOL,
  TL_METRIC_TYP_REL_SPEED_VOL,
  TL_METRIC_TYP_VHD,
];
const speeds = (timeOfDayOffset) => ['get', `s${timeOfDayOffset}`];
const typicalSpeeds = (timeOfDayOffset) => ['get', `ts${timeOfDayOffset}`];
const counts = (timeOfDayOffset) => ['get', `c${timeOfDayOffset}`];
const typicalCounts = (timeOfDayOffset) => ['get', `tc${timeOfDayOffset}`];

export function metricExprDeepSub(expr: Array<any>, sub) {
  // Substitutes METRIC_PLACEHOLDER for the passed sub
  for (let i = 0; i < expr.length; i++) {
    if (expr[i] === METRIC_PLACEHOLDER) {
      expr[i] = sub;
    } else if (Array.isArray(expr[i])) {
      expr[i] = metricExprDeepSub(expr[i], sub);
    }
  }
  return expr;
}

const ff_relative_speed_expr = ({ timeOfDayOffset }) => [
  '/',
  ['coalesce', speeds(timeOfDayOffset), typicalSpeeds(timeOfDayOffset)],
  ['get', 'freeflow_speed_kph'],
];
const typ_relative_speed_expr = ({ timeOfDayOffset }) => [
  'coalesce',
  [
    '/',
    ['coalesce', speeds(timeOfDayOffset), typicalSpeeds(timeOfDayOffset)],
    typicalSpeeds(timeOfDayOffset),
  ],
  1,
];

const typ_relative_count_expr = ({ timeOfDayOffset }) => [
  'interpolate',
  ['linear'],
  [
    '*',
    [
      '-',
      [
        '/',
        ['coalesce', counts(timeOfDayOffset), typicalCounts(timeOfDayOffset)],
        ['+', typicalCounts(timeOfDayOffset), 1],
      ],
      1,
    ],
    [
      'interpolate',
      ['linear'],
      ['max', typicalCounts(timeOfDayOffset), counts(timeOfDayOffset)],
      0,
      -0.1,
      50,
      -1,
    ],
  ],
  -5,
  -2,
  -1,
  -1,
  1,
  1,
];

const max_relative_count_expr = ({ timeOfDayOffset, hourlyMaxCount }) => [
  'let',
  'counts_',
  ['coalesce', counts(timeOfDayOffset), typicalCounts(timeOfDayOffset)],
  [
    'let',
    'typ_',
    ['+', typicalCounts(timeOfDayOffset), 1],
    [
      'interpolate',
      ['linear'],
      [
        '*',
        [
          '-',
          [
            'case',
            ['>', ['var', 'counts_'], ['var', 'typ_']],
            [
              '+',
              1,
              [
                '/',
                ['-', ['var', 'counts_'], ['var', 'typ_']],
                ['literal', hourlyMaxCount],
              ],
            ],
            ['/', ['var', 'counts_'], ['var', 'typ_']],
          ],
          1,
        ],
        [
          'interpolate',
          ['linear'],
          ['max', typicalCounts(timeOfDayOffset), counts(timeOfDayOffset)],
          0,
          -0.1,
          50,
          -1,
        ],
      ],
      -5,
      -2,
      -1,
      -2,
      0,
      0,
      1,
      1,
    ],
  ],
];

export const counts_absolute_size_expr = (
  timeOfDayOffset,
  hourlyMaxCount,
  base,
) => [
  'interpolate',
  ['linear'],
  [
    '/',
    [
      '*',
      ['max', counts(timeOfDayOffset), typicalCounts(timeOfDayOffset)],
      100,
    ],
    1000,
  ],
  0,
  0.5 * base,
  10,
  1.5 * base,
  100,
  3.0 * base,
];

const legend_stops_0_1_perc = [
  {
    value: 0,
    label: ['Stopped', '0%'],
  },
  {
    value: 1,
    label: ['Typical', '100%'],
  },
];

const legend_stops_atypical_volume = [
  {
    value: -2,
    label: ['(Higher than normal)', '500%'],
  },
  {
    value: -1,
    label: ['', '100%'],
  },
  {
    value: 0,
    label: ['', 'Typical'],
  },
  {
    value: 1,
    label: ['(Lower than normal)', '-100%'],
  },
];

const legend_stops_detour_volume = [
  {
    value: -2,
    label: ['(All detoured)', '100%'],
  },
  {
    value: -1,
    label: ['', '50%'],
  },
  {
    value: 0,
    label: ['', 'Typical'],
  },
  {
    value: 1,
    label: ['(Lower than normal)', '-100%'],
  },
];

const legend_stops_100_0_congestion = [
  {
    value: 0,
    label: 'Congested',
  },
  {
    value: 100,
    label: 'Freeflow',
  },
];

export const TL_METRIC_OPTIONS = {
  [TL_METRIC_FF_REL_SPEED_VOL]: {
    metric_expr: ff_relative_speed_expr,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: [
      {
        value: 0,
        label: ['Stopped', '0%'],
      },
      {
        value: 1,
        label: ['Freeflow', '100%'],
      },
    ],
    // legend_title: '% relative speed',
    // width: 'count_absolute',
  },
  [TL_METRIC_TYP_REL_SPEED_VOL]: {
    metric_expr: typ_relative_speed_expr,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_perc,
    // legend_title: '% relative speed',
    // width: 'count_absolute',
  },
  [TL_METRIC_TYP_REL_VOL]: {
    metric_expr: typ_relative_count_expr,
    colors: COLORMAP_VOLUME_M1_1,
    legend_stops: legend_stops_atypical_volume,
    // legend_title: '% relative count',
    width: 'count_absolute',
  },
  [TL_METRIC_TYP_VHD]: {
    metric_expr: max_relative_count_expr,
    colors: COLORMAP_VOLUME_M1_1,
    legend_stops: legend_stops_detour_volume,
  },
};
